// Import our external dependencies.
import { Workbox } from "workbox-window";
import ui from "ui"; //eslint-disable-line import/no-unresolved

// Create and Export a service worker instance.
const serviceWorker =
  "serviceWorker" in navigator ? makeServiceWorker() : false;
export default serviceWorker;

// -  - -  - -  - -  - -  - -  Private... - -  - -  - -  - -  - -  - -  - -  - |

// Function to create a service worker instance using Workbox.
function makeServiceWorker() {
  const wb = new Workbox("service-worker.js");

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener("waiting", () => {
    // Show a prompt to the user.
    if (!ui && !ui.showRefreshPrompt) return false;
    ui.showRefreshPrompt(function handleAccept() {
      return new Promise(resolve => {
        wb.addEventListener("controlling", resolve);
        wb.messageSW({ type: "SKIP_WAITING" });
      });
    });
  });

  // Link into the UI module by exposing the workbox object.
  if (ui && ui.setServiceWorker) ui.setServiceWorker(wb);

  wb.register();
}
